import React, { useState } from 'react';
import { Container, Typography, Card, CardContent, TextField, Button, Box, Stepper, Step, StepLabel, Grid } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSpring, animated } from '@react-spring/web';
import { styled } from '@mui/system';
import NavBar from '../components/Navbar/NavBar'; // Import NavBar
import emailjs from 'emailjs-com';

const steps = ['Company Info', 'Codebase Details', 'Scope Details'];

const Contact = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        companyName: '',
        email: '',
        budget: '',
        startDate: null,
        endDate: null,
        githubRepo: '',
        scope: '',
        branch: '',
        features: '',
        issues: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false); // New state to track form submission

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleDateChange = (date, name) => {
        setFormData({
            ...formData,
            [name]: date
        });
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleConfirm = () => {
        console.log('Form Data:', formData);
        const templateParams = {
            companyName: formData.companyName,
            email: formData.email,
            budget: formData.budget,
            startDate: formData.startDate ? formData.startDate.toLocaleDateString() : '',
            endDate: formData.endDate ? formData.endDate.toLocaleDateString() : '',
            githubRepo: formData.githubRepo,
            scope: formData.scope,
            branch: formData.branch,
            features: formData.features,
            issues: formData.issues
        };

        emailjs.send(
            'service_ilu97hp', // Replace with your service ID
            'template_02lhewq', // Replace with your template ID
            templateParams,
            'wiJMjLM9gDgsYPBGb' // Replace with your public key
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setIsSubmitted(true); // Set form submission state to true
        }, (err) => {
            console.log('FAILED...', err);
        });
    };

    const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

    const buttonStyles = {
        backgroundColor: '#ff6445',
        transition: 'background-color 0.3s',
        color: 'white',
        '&:hover': {
            backgroundColor: '#ffa400',
        },
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px'
    };

    const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: ownerState.active ? '#ffa400' : '#ff6445',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <StepIconRoot ownerState={{ completed, active }} className={className}>
                {props.icon}
            </StepIconRoot>
        );
    }

    return (
        <>
            <NavBar />
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1f1c1c', // Restore the background color
                    color: 'white', // Ensure text is white
                }}
            >
                <Container maxWidth="lg" sx={{ marginTop: { xs: '20vh', md: '15vh' } }}>
                    <Grid container spacing={8} alignItems="center">
                        {!isSubmitted ? ( // Conditionally render content based on form submission state
                            <>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        sx={{
                                            background: 'linear-gradient(90deg, #ff6445, #ffa400)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            backgroundClip: 'text',
                                            textFillColor: 'transparent',
                                        }}
                                    >
                                        Welcome
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                    >
                                        Let’s onboard you! Introduce yourself to our dev world 👋
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                    >
                                        Our onboarding process only takes 3 steps! Once you fill out the form we can list your project on our website and start the competition on the given date! 
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                        sx={{ color: '#ff6445' }}
                                    >
                                        Note: Higher budgets attract better developers
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: 4 }}>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                    <Typography sx={{ color: 'white' }}>{label}</Typography>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <animated.div style={fade}>
                                        {activeStep === 0 && (
                                            <Card sx={{ backgroundColor: '#241222', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                                                <CardContent>
                                                    <TextField
                                                        fullWidth
                                                        label="Company Name"
                                                        name="companyName"
                                                        value={formData.companyName}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Email"
                                                        name="email"
                                                        type="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Budget"
                                                        name="budget"
                                                        value={formData.budget}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <Box margin="normal">
                                                        <Typography sx={{ color: 'white' }}>Competition Start Date</Typography>
                                                        <DatePicker
                                                            selected={formData.startDate}
                                                            onChange={(date) => handleDateChange(date, 'startDate')}
                                                            dateFormat="MM/dd/yyyy"
                                                            customInput={<TextField fullWidth margin="normal" InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }} />}
                                                        />
                                                    </Box>
                                                    <Box margin="normal">
                                                        <Typography sx={{ color: 'white' }}>Competition End Date</Typography>
                                                        <DatePicker
                                                            selected={formData.endDate}
                                                            onChange={(date) => handleDateChange(date, 'endDate')}
                                                            dateFormat="MM/dd/yyyy"
                                                            customInput={<TextField fullWidth margin="normal" InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }} />}
                                                        />
                                                    </Box>
                                                    <Box display="flex" justifyContent="flex-end">
                                                        <Button
                                                            variant="contained"
                                                            sx={buttonStyles}
                                                            onMouseEnter={(e) => {
                                                                e.target.style.backgroundColor = '#ffa400';
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.style.backgroundColor = '#ff6445';
                                                            }}
                                                            onClick={handleNext}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        )}
                                        {activeStep === 1 && (
                                            <Card sx={{ backgroundColor: '#241222', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                                                <CardContent>
                                                    <TextField
                                                        fullWidth
                                                        label="GitHub Repo"
                                                        name="githubRepo"
                                                        value={formData.githubRepo}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Branch"
                                                        name="branch"
                                                        value={formData.branch}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Scope"
                                                        name="scope"
                                                        value={formData.scope}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        multiline
                                                        rows={4}
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <Box display="flex" justifyContent="space-between">
                                                        <Button
                                                            sx={buttonStyles}
                                                            onMouseEnter={(e) => {
                                                                e.target.style.backgroundColor = '#ffa400';
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.style.backgroundColor = '#ff6445';
                                                            }}
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            sx={buttonStyles}
                                                            onMouseEnter={(e) => {
                                                                e.target.style.backgroundColor = '#ffa400';
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.style.backgroundColor = '#ff6445';
                                                            }}
                                                            onClick={handleNext}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        )}
                                        {activeStep === 2 && (
                                            <Card sx={{ backgroundColor: '#241222', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                                                <CardContent>
                                                    <TextField
                                                        fullWidth
                                                        label="List Features You Need"
                                                        name="features"
                                                        value={formData.features}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        multiline
                                                        rows={4}
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Known Bugs"
                                                        name="issues"
                                                        value={formData.issues}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        multiline
                                                        rows={2}
                                                        InputProps={{ style: { backgroundColor: '#ff6445', color: 'white', borderRadius: '8px' } }}
                                                        InputLabelProps={{ style: { color: 'white' } }}
                                                    />
                                                    <Box display="flex" justifyContent="space-between">
                                                        <Button
                                                            sx={buttonStyles}
                                                            onMouseEnter={(e) => {
                                                                e.target.style.backgroundColor = '#ffa400';
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.style.backgroundColor = '#ff6445';
                                                            }}
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            sx={buttonStyles}
                                                            onMouseEnter={(e) => {
                                                                e.target.style.backgroundColor = '#ffa400';
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.style.backgroundColor = '#ff6445';
                                                            }}
                                                            onClick={handleConfirm}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        )}
                                    </animated.div>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        background: 'linear-gradient(90deg, #ff6445, #ffa400)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        backgroundClip: 'text',
                                        textFillColor: 'transparent',
                                    }}
                                >
                                    Congratulations! We will reply within 24 hours!
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Contact;
