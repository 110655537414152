import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl uppercase font-bold" style={{ color: 'rgb(255, 100, 69)' }}>How It Works</h2>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        <div className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundColor: '#242122' }}>
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12 fontFamily: 'Mulish Sans, sans-serif' " style={{ color: 'white' }}> Share Your Codebase</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48" style={{ color: 'white' }}>
                                Provide your relevant codebase for review. All rights to your code remain yours, including any approved contributions.                          </p>
                                <div className="flex justify-center my-4">
                                    <Link
                                        to="/contact#contact"
                                        className="text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                                        style={{
                                            backgroundColor: '#ff6445',
                                            transition: 'background-color 0.3s'
                                        }}
                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#ffa400'}
                                        onMouseLeave={(e) => e.target.style.backgroundColor = '#ff6445'}
                                    >
                                        Connect Github
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3" style={{ backgroundColor: '#242122' }}>
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12 fontFamily: 'Mulish Sans, sans-serif' " style={{ color: 'white' }}> Define Your Requirements</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48" style={{ color: 'white' }}>
                                Outline your project needs—features, optimizations, security concerns, timeline, and budget. We’ll set up the prize structure based on your specifications.                     </p>
                                <div className="flex justify-center my-4">
                                    <Link
                                        to="/contact#contact"
                                        className="text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                                        style={{
                                            backgroundColor: '#ff6445',
                                            transition: 'background-color 0.3s'
                                        }}
                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#ffa400'}
                                        onMouseLeave={(e) => e.target.style.backgroundColor = '#ff6445'}
                                    >
                                        Set Requirements
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3" style={{ backgroundColor: '#242122' }}>
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12" style={{ color: 'white' }}>Launch the Competition</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48" style={{ color: 'white' }}>
                                Your project and prize pool are listed to attract top developers. As developers submit code tailored to your needs, our expert reviewers ensure it meets the highest standards.                              </p>
                                <div className="flex justify-center my-4">
                                    <Link
                                        to="/contact#contact"
                                        className="text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                                        style={{
                                            backgroundColor: '#ff6445',
                                            transition: 'background-color 0.3s'
                                        }}
                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#ffa400'}
                                        onMouseLeave={(e) => e.target.style.backgroundColor = '#ff6445'}
                                    >
                                        View Competitions
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3" style={{ backgroundColor: '#242122' }}>
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12" style={{ color: 'white' }}>Review and Approve</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48" style={{ color: 'white' }}>
                                Our reviewers carefully evaluate submissions and present the best options to you. You make the final decision on which code to approve.                                </p>
                                <div className="flex justify-center my-4">
                                    <Link
                                        to="/contact#contact"
                                        className="text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                                        style={{
                                            backgroundColor: '#ff6445',
                                            transition: 'background-color 0.3s'
                                        }}
                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#ffa400'}
                                        onMouseLeave={(e) => e.target.style.backgroundColor = '#ff6445'}
                                    >
                                        Become a Judge
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;
