import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = ({ handleClick }) => {
  return (
    <>
      <a
        className="px-4 text-white hover:text-orange-400"
        href="https://codecall.gitbook.io/code-call/"
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        Documentation
      </a>
      <HashLink
        className="px-4 text-white hover:text-orange-400"
        to="/contact#contact"
        onClick={handleClick}
      >
        Contact Us
      </HashLink>
      <a
  href="https://codecallappfrontend.vercel.app"
  className="text-white inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
  style={{
    background: 'linear-gradient(45deg, #ff6445, #ffa400)',
    transition: 'background 0.3s'
  }}
  onMouseEnter={(e) => e.target.style.background = 'linear-gradient(45deg, #ff7d16, #ffa400)'}
  onMouseLeave={(e) => e.target.style.background = 'linear-gradient(45deg, #ff6445, #ffa400)'}
>
  Launch App
</a>

    </>
  );
};

export default NavLinks;
