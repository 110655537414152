import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import "../index.css";
import sneakpeekImage from '../images/sneakpeek.png'; // Import your sneak peek image

const ComingSoon = () => {
  return (
    <>
      <NavBar />
      <div className="coming-soon-page">
        <div className="coming-soon-content">
          <h1 className="coming-soon-text">Coming Soon</h1>
          <img 
            src={sneakpeekImage} 
            alt="Sneak Peek" 
            className="sneakpeek-image" 
            style={{ width: '75%', height: 'auto', display: 'block', margin: '0 auto' }}
            />
          <Link
            to="/"
            className="text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
            style={{
              backgroundColor: '#ff6445',
              transition: 'background-color 0.3s'
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#ffa400';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#ff6445';
            }}
          >
            Return back
          </Link>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
