import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import "../index.css";
import Footer from '../components/Footer';
import { Typography } from "@material-tailwind/react";

const jobPosts = [
  {
    title: "Full Stack Developer",
    description: (
      <>
        <Typography variant="h4" color="blue-gray" className="mb-4">
          Full Stack Developer
        </Typography>
        <Typography variant="paragraph" className="mb-4">
        At CodeCall, we are transforming how software development is done. Our platform connects developers directly with businesses through a bounty system, allowing them to work on projects they choose and get paid based on their contributions. We empower developers to pick what they want to work on, solve real-world problems, and earn from the bounties they claim. Whether you're looking to work on full-stack projects or specialize in front-end or back-end development, you'll find exciting opportunities at CodeCall.        
        </Typography>
        <Typography variant="h6" className="mb-2">
          At CodeCall You Will:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Contribute code to real-world projects that require full-stack development, from building scalable back-end systems to optimizing front-end components.</li>
          <li>Work remotely and independently, selecting the projects that interest you and fit your skillset.</li>
          <li>Use a variety of popular technologies, including JavaScript, React, Node.js, SQL/NoSQL databases, Docker, and AWS.</li>
          <li>Collaborate with cross-functional teams and other developers in the community, while gaining exposure to cutting-edge tools and frameworks.</li>
          <li>Participate in a flexible, developer-first environment, where your feedback is crucial to improving our platform.</li>
          <li>Engage with a community of like-minded engineers focused on learning, collaboration, and growth.</li>
        </ul>
        <Typography variant="h6" className="mb-2">
          What We Look For:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Proven experience as a Full Stack Developer with knowledge of both front-end and back-end technologies.</li>
          <li>Proficiency in React, Node.js, and experience with REST APIs.</li>
          <li>Strong problem-solving abilities and the capacity to work independently.</li>
          <li>Bachelor's degree in Computer Science or related field.</li>
        </ul>
        <Typography variant="h6" className="mb-2">

        Why CodeCall:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Remote work, giving you the freedom to work from anywhere.</li>
          <li>Bounty-based compensation, where your pay depends on the bounties you claim and complete, providing flexibility and opportunity to maximize your earnings.
          </li>
          <li>The opportunity to work on projects you choose, allowing you to focus on the most interesting and rewarding work.
          </li>
          <li>Be part of a platform that is designed by developers, for developers—your feedback is valued, and we are always open to improving the experience for our community.
          </li>
          <li>
          Continuous learning opportunities and exposure to real-world projects to enhance your portfolio and skills.
          </li>

        </ul>

      </>
    ),
    applyLink: "mailto:careers@codecall.xyz?subject=Application%20for%20Full%20Stack%20Developer",
  },
  {
    title: "React Developer",
    description: (
      <>
        <Typography variant="h4" color="blue-gray" className="mb-4">
          React Developer
        </Typography>
        <Typography variant="paragraph" className="mb-4">
        As a React Developer at CodeCall, you will contribute to creating smooth, dynamic, and performant web applications that serve both businesses and developers. You’ll be building reusable components, optimizing performance, and ensuring the best user experience. Since CodeCall is a developer-first platform, you’ll also have opportunities to collaborate with fellow engineers, improve our platform, and build innovative solutions to challenging problems.         
        </Typography>
        <Typography variant="h6" className="mb-2">
          At CodeCall You Will:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Develop and implement user-facing features using React.js.</li>
          <li>Build reusable components and front-end libraries for future use.</li>
          <li>Ensure the technical feasibility of UI/UX designs, working closely with designers.</li>
          <li>Optimize applications for maximum speed and scalability.</li>
          <li>Collaborate with other developers and teams to innovate and contribute to the overall project architecture.</li>
        </ul>
        <Typography variant="h6" className="mb-2">
          What We Look For:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Proficiency in JavaScript and strong experience with React.js.</li>
          <li>Familiarity with REST APIs and front-end build tools like Webpack and Babel.</li>
          <li>Experience with state management libraries (Redux or Context API).</li>
          <li>Good understanding of responsive design, HTML5, CSS3, and component-driven development.</li>
        </ul>
        <Typography variant="h6" className="mb-2">

Why CodeCall:
</Typography>
<ul className="list-disc ml-5 mb-4">
  <li>Remote work, giving you the freedom to work from anywhere.</li>
  <li>Bounty-based compensation, where your pay depends on the bounties you claim and complete, providing flexibility and opportunity to maximize your earnings.
  </li>
  <li>The opportunity to work on projects you choose, allowing you to focus on the most interesting and rewarding work.
  </li>
  <li>Be part of a platform that is designed by developers, for developers—your feedback is valued, and we are always open to improving the experience for our community.
  </li>
  <li>
  Continuous learning opportunities and exposure to real-world projects to enhance your portfolio and skills.
  </li>

</ul>
      </>
    ),
    applyLink: "mailto:careers@codecall.xyz?subject=Application%20for%20React%20Developer",
  },
  {
    title: "Backend Java and Spring Developer",
    description: (
      <>
        <Typography variant="h4" color="blue-gray" className="mb-4">
          Backend Java and Spring Developer
        </Typography>
        <Typography variant="paragraph" className="mb-4">
        Join our team as a Backend Java and Spring Developer and be part of a platform that directly impacts how businesses and developers collaborate. You’ll be responsible for building robust back-end systems that power our bounty platform, ensuring scalability and performance. You’ll work remotely, solving complex challenges while collaborating with other developers and engineers.
        </Typography>
        <Typography variant="h6" className="mb-2">
          At CodeCall You Will:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Develop and maintain server-side components using Java and Spring Framework.</li>
          <li>Design scalable APIs that integrate seamlessly with our front-end and mobile apps.</li>
          <li>Implement data storage solutions, optimizing databases for performance.</li>
          <li>Ensure high availability and performance of server-side services.</li>
          <li>Collaborate with front-end developers and other teams to ensure smooth integration between back-end and front-end systems.</li>
        </ul>
        <Typography variant="h6" className="mb-2">
          What We Look For:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Strong experience with Java and Spring Framework.</li>
          <li>Proficiency in building and working with REST APIs.</li>
          <li>Familiarity with SQL and NoSQL databases.</li>
          <li>Understanding of microservices architecture.</li>
          <li>Experience with cloud platforms (AWS or similar) is a plus.</li>
        </ul>
        <Typography variant="h6" className="mb-2">

Why CodeCall:
</Typography>
<ul className="list-disc ml-5 mb-4">
  <li>Remote work, giving you the freedom to work from anywhere.</li>
  <li>Bounty-based compensation, where your pay depends on the bounties you claim and complete, providing flexibility and opportunity to maximize your earnings.
  </li>
  <li>The opportunity to work on projects you choose, allowing you to focus on the most interesting and rewarding work.
  </li>
  <li>Be part of a platform that is designed by developers, for developers—your feedback is valued, and we are always open to improving the experience for our community.
  </li>
  <li>
  Continuous learning opportunities and exposure to real-world projects to enhance your portfolio and skills.
  </li>

</ul>
      </>
    ),
    applyLink: "mailto:careers@codecall.xyz?subject=Application%20for%20Backend%20Java%20Developer",
  },
  {
    title: "Solidity Developer",
    description: (
      <>
        <Typography variant="h4" color="blue-gray" className="mb-4">
          Solidity Developer
        </Typography>
        <Typography variant="paragraph" className="mb-4">
        As a Solidity Developer at CodeCall, you will contribute to building our blockchain and smart contract infrastructure. You will be responsible for writing secure and efficient Solidity code, testing, deploying, and interacting with smart contracts to enhance the developer-bounty system. This is an exciting opportunity to work remotely and be part of an evolving space in blockchain development.
        </Typography>
        <Typography variant="h6" className="mb-2">
          At CodeCall You Will:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Develop secure smart contracts using Solidity for blockchain-based systems.</li>
          <li>Test and deploy contracts on Ethereum and other blockchains.</li>
          <li>Participate in blockchain research and innovation within the platform.</li>
          <li>Collaborate with other developers and team members to integrate smart contract functionality into the overall system.</li>
          <li>Ensure the security and efficiency of contracts through thorough testing and auditing.</li>
        </ul>
        <Typography variant="h6" className="mb-2">
          What We Look For:
        </Typography>
        <ul className="list-disc ml-5 mb-4">
          <li>Strong experience with Solidity and Ethereum development.</li>
          <li>Understanding of blockchain architecture, smart contracts, and consensus algorithms.</li>
          <li>Experience with Truffle, Hardhat, or other Solidity testing frameworks.</li>
          <li>Familiarity with web3.js or ethers.js for interacting with blockchain networks.</li>
          <li>Experience with auditing and securing smart contracts.</li>
        </ul>
        <Typography variant="h6" className="mb-2">

Why CodeCall:
</Typography>
<ul className="list-disc ml-5 mb-4">
  <li>Remote work, giving you the freedom to work from anywhere.</li>
  <li>Bounty-based compensation, where your pay depends on the bounties you claim and complete, providing flexibility and opportunity to maximize your earnings.
  </li>
  <li>The opportunity to work on projects you choose, allowing you to focus on the most interesting and rewarding work.
  </li>
  <li>Be part of a platform that is designed by developers, for developers—your feedback is valued, and we are always open to improving the experience for our community.
  </li>
  <li>
  Continuous learning opportunities and exposure to real-world projects to enhance your portfolio and skills.
  </li>

</ul>

      </>
    ),
    applyLink: "mailto:careers@codecall.xyz?subject=Application%20for%20Solidity%20Developer",
  },
];

const Careers = () => {
    const [selectedJobIndex, setSelectedJobIndex] = useState(0);
  
    return (
      <>
        <NavBar />
        {/* Add padding at the top to prevent overlapping with navbar */}
        <div className="flex flex-col md:flex-row careers-page pt-20 md:pt-24">
          {/* Sidebar */}
          <div className="md:w-1/4 sidebar p-4">
            <Typography variant="h5" className="sidebar-title mb-4">
              Open Positions
            </Typography>
            <ul>
              {jobPosts.map((job, index) => (
                <li key={index} className="mb-2">
                  <button
                    onClick={() => setSelectedJobIndex(index)}
                    className={`sidebar-button ${
                      selectedJobIndex === index
                        ? 'sidebar-button-active'
                        : ''
                    }`}
                  >
                    {job.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
  
  
 {/* Job Description */}
 <div className="md:w-3/4 p-6 text-white">
          {jobPosts[selectedJobIndex].description}
          <a
            href={"https://discord.gg/UNdgzxZEJq"}
            className="mt-6 apply-button"
          >
            Apply Now
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Careers;