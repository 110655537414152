import React from 'react';
import { ReactComponent as IbmSvg } from '../images/clients/ibmsvg.svg'; 
import { ReactComponent as DeutscheSvg } from '../images/clients/deutsche.svg'; 
import { ReactComponent as GoogleSvg } from '../images/clients/google.svg'; 

const svgContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '1rem', // Adjust padding to better control the logo size
    transition: 'all 0.3s ease-in-out',
    opacity: '100',
    hover: {
        opacity: '100'
    }
};

const googleStyle = {
    height: '7rem', // Adjust the size as needed
    width: 'auto',
    fill: 'grey'
};

const deutscheStyle = {
    height: '4rem', // Adjust the size as needed
    width: 'auto',
    fill: 'grey'
};

const ibmStyle = {
    height: '3rem', // Adjust the size as needed
    width: 'auto',
    fill: 'grey'
};

const Clients = () => {
    return (
        <div className="mt-8" style={{ backgroundColor: '#242122' }}>
            <section data-aos="fade-up">
                <div className="my-2 py-2" style={{ paddingTop: '2rem' }}>
                    <h2 className="mt-2 mx-4 text-center text-xl lg:text-md text-white" style={{ marginBottom: '0.5rem' }}>
                        Our team has experience with top-tier businesses.
                    </h2>
                </div>
                <div className="p-4" data-aos="fade-in" data-aos-delay="600" style={{ paddingTop: '0.5rem', paddingBottom: '2rem' }}>
                    <div className="grid sm:grid-cols-1 lg:grid-cols-1 justify-center items-center">
                        <div className="flex justify-center space-x-6">
                            <div style={svgContainerStyle}>
                                <DeutscheSvg style={deutscheStyle} />
                            </div>
                            <div style={svgContainerStyle}>
                                <GoogleSvg style={googleStyle} />
                            </div>
                            <div style={svgContainerStyle}>
                                <IbmSvg style={ibmStyle} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Clients;
