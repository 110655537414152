import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';
import rocketImg from '../images/rocket.png'; 

const Intro = () => {
    return (
        <>
            <div className="bg-light-purple min-h-screen flex items-center" style={{ backgroundColor: '#1f1c1c', minHeight: '45vh' }}> {/* Adjusted min-height */}
                <div className="m-auto max-w-6xl p-2 md:p-8 h-5/6" id='about'> {/* Adjusted padding */}
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={rocketImg} />
                        </div>
                        <div className="flex-col my-2 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-4" data-aos="zoom-in" data-aos-delay="500"> {/* Adjusted margin and padding */}
                            <h3 className="text-3xl text-purple font-bold" style={{ color: '#ff6445' }}>
                            Get Optimized, Secure Code for Your Business
                            </h3>
                            <div>
                                <p className='my-2 text-md text-gray-700 ' style={{ color: 'white' }}> {/* Adjusted margin */}
                                Pay only for the results you approve, tailored to your requirements.                                </p>
                            </div>
                            <div>
                                <p className='my-2 text-md text-gray-700 ' style={{ color: 'white' }}> {/* Adjusted margin */}
                                With access to a global network of developers and expert reviewers, you’re guaranteed optimized and secure code.
                                </p>
                            </div>
                            <Link
                                to="/contact"
                                className="text-white inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
                                style={{
                                    backgroundColor: '#ff6445',
                                    transition: 'background-color 0.3s'
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#ffa400';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#ff6445';
                                }}
                            >
                                Contact us
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#ffffff">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
