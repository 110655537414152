import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/hero-img.png'; // Updated import statement
import "../index.css";

const Hero = () => {
  

    return (
        <>
            <div className="hero" id='hero' style={{ backgroundColor: '#1f1c1c', fontFamily: "Mulish" }}> 
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" style={{ fontFamily: "Mulish Sans, sans-serif" }} >
                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left" style={{ fontFamily: "Mulish Sans, sans-serif" }}>
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold">
                                <span
                                    style={{
                                        background: 'linear-gradient(90deg, #ff6445, #ffa400)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        backgroundClip: 'text',
                                        textFillColor: 'transparent',
                                        fontFamily: "Mulish",
                                    }}
                                >
                                    Pay for results,
                                </span>
                                <br />
                                <span
                                    style={{
                                        background: 'linear-gradient(90deg, #ffa400, #ff6445)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        backgroundClip: 'text',
                                        textFillColor: 'transparent',
                                        fontFamily: "Mulish",
                                    }}
                                >
                                    Not hours.
                                </span>
                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-5" style={{ color: '#ff6445', whiteSpace: 'pre-line', fontFamily: 'Mulish Sans, sans-serif' }}>
                                Set the rules, approve the code, and only pay for results! 
                            </div>
                            <ul className="text-lg mb-5" style={{ color: '#ffff' }}>
                                <li>Connect with Top Global Developers</li>
                                <li>Run Competitive Coding Challenges for Tailored Solutions</li>
                                <li>Receive High-Quality, Expert-Validated Code</li>
                            </ul>

                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link
                                    to="/contact"
                                    className="text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                                    style={{
                                        backgroundColor: '#ff6445',
                                        transition: 'background-color 0.3s'
                                    }}
                                    onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = '#ffa400';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = '#ff6445';
                                    }}
                                >
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#ffffff">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className="flex lg:justify-start w-full lg:w-1/2">
                            <img alt="card img" className="rounded-t float-right" src={heroImg} style={{ width: '100%', height: 'auto', marginRight: 'auto' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
